/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/case-management-spa/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const basePath = "/case-management";

export const applicationId = "18633d59-3a73-4d82-a1a8-2404a7281a8c";

export const CaseManagementPermissions = {
  view: "35fb22da-96da-4522-bd07-cc615d457979",
  createCase: "800cc1bd-2275-416f-9095-acd08a8c1ade",
  updateRemoveCase: "99038add-961f-4230-8bf1-240a7028c07a",
  updateCaseStage: "a0e9ea32-816e-4e21-8dce-7c0651934fe0",
  addActionItem: "8c165384-7a5a-4a87-9e3f-d8d3b82b2163",
  updateRemoveActionItem: "b2248f1a-7fb3-4b2a-807f-c0bfa9cd1a09",
  addActionItemComment: "c385f9b4-7c60-4a24-8633-62dd242dcf91",
  updateRemoveActionItemComment: "0d64a733-f1cf-4838-a967-01803fd34a6e",
  addPostCommunication: "fb544ed0-e423-4a00-a6a9-01c59df133db",
  updateRemovePostCommunication: "a20de117-1b24-4dfa-a1f1-ee41d8a4f923",
  addCommentOnPostCommunication: "77fea48c-bf32-478a-9754-79b82543eec4",
  updateRemoveCommentOnPostCommunication: "3bb7ce80-df73-4624-aaac-4f8fc5fb0541",
  addDetailedDescription: "7a895697-0ee5-4e33-b3aa-587b9128dea8",
  updateRemoveDetailedDescription: "bf36a3cc-ab1b-4940-a5f6-bb2e44249528",
  addFile: "f1fec1d8-60b2-4c4c-a591-ec34245cd136",
  updateRemoveFile: "087ad60c-825e-40f9-ae4e-2a7fe453cdea",
  addRelatedCases: "e9504ded-8b3d-4c5c-a191-56657681f6d4",
  updateRemoveRelatedCases: "02f4dd6d-1e10-461b-8bb2-854cae83a1b0",
  addInvestigator: "0bc7ad51-bb20-426c-91a2-a08a64af214d",
  updateRemoveInvestigator: "95aa6e3e-23e1-458b-abc1-1823df4232fc",
  addValidator: "f7a463a5-a470-4b8a-9c08-c04a1bd994b8",
  updateRemoveValidator: "4a99aa74-c93d-48a7-b70d-de91718d7e33",
  addCollaborator: "403f3ab8-8f9d-47c6-8a9c-4901c32801b9",
  updateRemoveCollaborator: "daf64390-d0e9-4ce5-aecc-00ec20936737",
  addBenefitAndValueGeneration: "9bd763d5-9c93-4224-af6b-a9cacacdb421",
  updateRemoveBenefitAndValueGeneration: "b2026d77-ce72-417d-8c5e-63eeb0fab637",
  addCaseLearnings: "2b60f039-26ce-4142-ba94-8fbbefed1857",
  updateRemoveCaseLearnings: "cb4f9e2e-9f65-4ce1-a4d3-76b385315105",
  createCaseReport: "f8add5a7-c39b-488e-8145-a905ee1d24a8",
  updateRemoveCaseReport: "688eb519-6802-44c8-b08a-f92ba5503e8d",
  addCaseReportDistributed: "10a306da-ef9f-4b2e-b8a6-1e263f8a5847",
  updateRemoveCaseReportDistributed: "27900d07-0f75-432c-a2eb-e590ec23f8c7"
};

/** CRUD definitions for Case Management Files */
export const CaseManagementFilesCRUDDefinitions = {
  create: CaseManagementPermissions.addFile,
  read: CaseManagementPermissions.view, // TODO something else?
  update: CaseManagementPermissions.updateRemoveFile,
  delete: CaseManagementPermissions.updateRemoveFile
};

export const CaseManagementCRUDDefinitions = [
  { label: "Files", crud: CaseManagementFilesCRUDDefinitions }
];

const config: Application = {
  applicationId,
  name: "@nutrien-operations/case-management-spa",
  displayName: "Case Management",
  icon: "case",
  path: {
    value: basePath
  },
  order: 1,
  permissions: CaseManagementPermissions,
  menu: {
    label: "Case Management",
    path: basePath,
    icon: "case",
    permission: CaseManagementPermissions.view,
    children: [
      {
        label: "Cases",
        path: "/",
        icon: "settings",
        defaultCollapsed: true,
        permission: CaseManagementPermissions.view,
        children: [
          {
            label: "Search",
            path: "/search",
            permission: CaseManagementPermissions.view
          },
          {
            label: "Create",
            path: "/create",
            permission: CaseManagementPermissions.createCase
          }
        ]
      },
      {
        label: "Admin",
        path: "/admin",
        permission: CaseManagementPermissions.view // TODO better permission for administration? is administration possible?
      }
    ]
  },
  swagger: {
    localdev: {
      url: "https://dev-case-management-swagger-ui.np.nutrien.com"
    },
    development: {
      url: "https://dev-case-management-swagger-ui.np.nutrien.com"
    },
    uat: {
      url: "https://uat-case-management-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-case-management-swagger-ui.np.nutrien.com"
    }
  }
};

export default config;
