type APIConfig = Record<
  | "accelerator"
  | "case-management"
  | "calculation-engine"
  | "common-data"
  | "legacy-accelerator"
  | "list-of-values"
  | "manual-entry"
  | "material-balance"
  | "mine-maintenance-portal"
  | "online-log-sheets"
  | "potash-reporting"
  | "safe-production-calendar"
  | "shift-logs",
  string
>;

type AmplifyAuthConfig = {
  adfsFederatedIdentityProviderId: string;
  region: string;
  userPoolWebClientId: string;
  userPoolId: string;
  oauth: {
    domain: string;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
};

type AmplifyConfig = {
  Auth: AmplifyAuthConfig;
};

type PowerBiConfig = {
  redirectUri: string;
  clientId: string;
  authority: string;
};
type SentryConfig = {
  dsn: string;
  sampleRate: string;
  replayErrorSampleRate: string;
};

type Environment = "localdev" | "development" | "uat" | "production";

type AppConfig = {
  environment: Environment;
  API: APIConfig;
  Amplify: AmplifyConfig;
  PowerBi: PowerBiConfig;
  Sentry: SentryConfig;
};

const loadEnvironmentVariable = <T = string>(name: string): T => {
  return process.env[name] as unknown as T;
};

export const config: AppConfig = {
  environment: loadEnvironmentVariable<Environment>("NODE_ENV"),
  API: {
    accelerator: loadEnvironmentVariable("API_ACCELERATOR"),
    "calculation-engine": loadEnvironmentVariable("API_CALCULATION_ENGINE"),
    "case-management": loadEnvironmentVariable("API_CASE_MANAGEMENT"),
    "common-data": loadEnvironmentVariable("API_COMMON_DATA"),
    "legacy-accelerator": loadEnvironmentVariable("LEGACY_API_ACCELERATOR"),
    "list-of-values": loadEnvironmentVariable("API_LIST_OF_VALUES"),
    "manual-entry": loadEnvironmentVariable("API_MANUAL_ENTRY"),
    "material-balance": loadEnvironmentVariable("API_MATERIAL_BALANCE"),
    "mine-maintenance-portal": loadEnvironmentVariable("API_MINE_MAINTENANCE_PORTAL"),
    "online-log-sheets": loadEnvironmentVariable("API_OLS"),
    "potash-reporting": loadEnvironmentVariable("API_POTASH_REPORTING"),
    "safe-production-calendar": loadEnvironmentVariable("API_SAFE_PRODUCTION_CALENDAR"),
    "shift-logs": loadEnvironmentVariable("API_SHIFT_LOGS")
  },
  Amplify: {
    Auth: {
      adfsFederatedIdentityProviderId: loadEnvironmentVariable(
        "ADFS_FEDERATED_IDENTITY_PROVIDER_ID"
      ),
      region: loadEnvironmentVariable("REGION"),
      userPoolWebClientId: loadEnvironmentVariable("USER_POOL_WEB_CLIENT_ID"),
      userPoolId: loadEnvironmentVariable("USER_POOL_ID"),
      oauth: {
        domain: loadEnvironmentVariable("DOMAIN"),
        scope: ["email", "openid", "profile", "transactions/get", "transactions/post"],
        redirectSignIn: loadEnvironmentVariable("REDIRECT_SIGN_IN"),
        redirectSignOut: loadEnvironmentVariable("REDIRECT_SIGN_OUT"),
        responseType: "code"
      }
    }
  },
  PowerBi: {
    redirectUri: loadEnvironmentVariable("POWER_BI_REDIRECT_URI"),
    clientId: loadEnvironmentVariable("POWER_BI_CLIENT_ID"),
    authority: loadEnvironmentVariable("POWER_BI_AUTHORITY")
  },
  Sentry: {
    dsn: loadEnvironmentVariable("SENTRY_DSN"),
    replayErrorSampleRate: loadEnvironmentVariable("SENTRY_DSN_REPLAY_ERROR_SAMPLE_RATE"),
    sampleRate: loadEnvironmentVariable("SENTRY_DSN_SAMPLE_RATE")
  }
};

export default config;
